import { Link } from '@inertiajs/react';
import LogoFooter from 'Components/Icons/LogoFooter';
import PageWidthConstraint from 'Components/PageWidthConstraint';
import { format } from 'date-fns';
import React from 'react';
import useUser from 'Support/Hooks/useUser';
import route from 'ziggy-js';

const Footer = ({ catalogues }) => {
  const user = useUser();

  return (
    <div className="flex w-full justify-center bg-gray-900 pb-10 pt-28 text-white">
      <PageWidthConstraint>
        <div className="flex flex-col justify-between text-sm sm:flex-row">
          <div className="flex flex-col items-center sm:block">
            <LogoFooter />

            <div className="mb-8 mt-8 flex flex-col gap-1 text-center text-xs font-medium text-gray-500 sm:mb-0 sm:text-left">
              <div>21 McGiven Drive, New Plymouth, New Zealand</div>
              <div>Phone: + 64 6 753 5346</div>
              <div>
                Email: <a href="mailto:info@brooklands.co.nz">info@brooklands.co.nz</a>
              </div>
            </div>
          </div>

          <div>
            <div className="flex flex-wrap justify-center gap-3 text-xs font-normal text-gray-200 sm:items-start sm:justify-end sm:gap-8 sm:pl-10 sm:text-sm">
              {catalogues.map((catalogue, index) => (
                <Link key={index} href={route('browse.catalogue', catalogue.id)} className="whitespace-nowrap">
                  {catalogue.name}
                </Link>
              ))}
              <Link href={route('browse.catalogue.sustainable')} className="whitespace-nowrap">
                Sustainable
              </Link>

              {!!user && <Link href={route('browse.catalogue.featured')}>New Products</Link>}
              {!!user && <Link href={route('browse.catalogue.clearance')}>Specials</Link>}
              {/*<Link href={route('about')}>About</Link>*/}
              <Link href={route('contact')}>Contact</Link>
            </div>
          </div>
        </div>

        {/*<div className="my-8 flex flex-col items-center justify-between gap-2 border-b border-t border-gray-800 py-10 md:flex-row">*/}
        {/*  <div className="text-xl font-semibold sm:text-3xl">Stay in the loop</div>*/}
        {/*  <div className="flex w-full flex-col gap-4 sm:flex-row sm:items-center">*/}
        {/*    /!*todo*!/*/}
        {/*    <input*/}
        {/*      placeholder="Enter your email"*/}
        {/*      name="email"*/}
        {/*      type="email"*/}
        {/*      className="rounded-lg border-0 font-normal text-black placeholder:text-gray-600"*/}
        {/*    />*/}
        {/*    <PrimaryButton>Subscribe</PrimaryButton>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div className="flex flex-col justify-between gap-3 text-xs font-normal text-gray-500 sm:flex-row sm:gap-4 sm:text-sm md:gap-8">
          <div className="flex flex-col items-center justify-between sm:items-start sm:gap-4 lg:flex-row lg:gap-8">
            <div>&copy; Copyright {format(new Date(), 'yyyy')} Brooklands Pet Products</div>
            <a href="https://webfox.co.nz" target="_blank">
              Website by Webfox
            </a>
          </div>
          <div className="flex justify-center gap-4 sm:justify-between lg:gap-8">
            <Link href={route('terms')}>Privacy Policy</Link>
            <Link href={route('trade')}>Terms of Trade</Link>
          </div>
        </div>
      </PageWidthConstraint>
    </div>
  );
};

export default Footer;
